<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="500"
      @cancel="visible = false"
  >
    <div v-if="auditList.length>0" class="list-div">
      <a-timeline>
        <a-timeline-item v-for="item in auditList" :key="item.id">
          <a-tag :color="item.passFlag | dictName('postStatus', 'color')" style="cursor: pointer">{{
              item.passFlag | dictName("postStatus")
            }}
          </a-tag>
          <span>{{ item.auditUserName }}</span>
          <span style="padding:0 10px;">{{ item.auditTime }}</span>
          <span>{{ item.auditRemark }}</span>
        </a-timeline-item>
      </a-timeline>
    </div>
    <div class="input-text">
      <a-textarea
          v-model="auditRemark"
          placeholder="请输入不通过的原因"
          :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </div>
    <div>
      <div style="padding-top:10px;color:#FF9900;">审核不通过常见原因（点击选择，可以多选）：</div>
      <a-tag color="red" v-for="item in reasonList" :key="item" style="cursor: pointer;margin:5px"
             @click="addReason(item)">
        {{ item }}
      </a-tag>
    </div>
    <template slot="footer">
      <div class="modal-btn">
        <a-button type="primary" :loading="confirmLoading" @click="handleOk(1)">
          通过
        </a-button>
        <a-button type="danger" :loading="confirmLoading" @click="handleOk(-1)">
          不通过
        </a-button>
        <a-button type="default" :loading="confirmLoading" @click="visible=false" style="margin-left:100px;">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: "AuditModal",
  data() {
    return {
      visible: false,
      title: "",
      confirmLoading: false,
      auditRemark: "",
      record: {},
      auditList: []
    };
  },
  computed: {
    reasonList() {
      return '身份证照片无效,身份证照片不清晰,其他'.split(/,/)
    },
  },
  methods: {
    showModal(record) {
      this.visible = true;
      this.record = record;
      this.auditRemark = '';
      this.auditList = [];
      this.title = "审核用户：" + record.username;
      this.$post("/user/auditHistory", {userId: record.uid}).then(res => {
        this.auditList = res.data || []
      })
    },
    addReason(item) {
      this.auditRemark = this.auditRemark + (this.auditRemark ? "，" : "") + item
    },
    handleOk(operate) {
      this.confirmLoading = true;
      if (operate == -1 && !this.auditRemark) {
        this.confirmLoading = false;
        this.$message.error("请输入审核不同的理由");
        return;
      }
      let data = {
        passFlag: operate,
        uid: this.record.uid,
        auditRemark: this.auditRemark
      }

      this.$postJson("user/authByAdmin", data)
          .then((res) => {
            this.confirmLoading = false;
            this.visible = false;
            this.$emit("success", operate);
          })
          .catch((error) => {
            this.confirmLoading = false;
          });
    },
  },
};
</script>

<style lang="less" scoped>
.modal-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
